










































































import Vue from 'vue';
import { IFetchVaultItems, IStore } from '../store';
import List, { RefreshObj } from '../components/List.vue';
import CustomButton from '../components/Button.vue';
import OcaEditView from './FormEditView.vue';
import { MimeType, VaultItem, VaultMinMeta, VaultPostItem, VaultSchema } from 'vaultifier/dist/module';
import { ActionType } from '@/store/action-type';
import { FetchState } from '@/store/fetch-state';
import { getInstance } from '@/services';
import Spinner from './Spinner.vue';
import { ConfigService } from '@/services/config-service';
import { NetworkResponse } from 'vaultifier/dist/module/communicator';
import { Action, executeAction, getActionsFromConfig } from '@/utils/actions';

interface IData {
  selectedSchema?: VaultSchema,
  showEditView: boolean,
  editViewSchema?: VaultSchema,
  isSaving: boolean,
  isExecutingAction: boolean,
  saveMessage?: string,
}

export default Vue.extend({
  mounted() {
    this.initialize();
  },
  data: (): IData => ({
    selectedSchema: undefined,
    showEditView: false,
    editViewSchema: undefined,
    isSaving: false,
    isExecutingAction: false,
    saveMessage: undefined,
  }),
  components: {
    CustomButton,
    OcaEditView,
    List,
    Spinner,
  },
  methods: {
    async initialize() {
      await this.fetchSchemas();
    },
    async selectSchema(schema: VaultSchema) {
      this.selectedSchema = schema;

      await this.fetchVaultItems();
    },
    async selectVaultItem(item?: VaultMinMeta) {
      await this.$store.dispatch(ActionType.FETCH_VAULT_ITEM, item);
    },
    async fetchSchemas() {
      this.selectedSchema = undefined;
      await this.$store.dispatch(ActionType.FETCH_SCHEMA_DRIS);
    },
    async fetchVaultItems(refreshObj?: RefreshObj) {
      let fetchObj: IFetchVaultItems = {
        schema: this.selectedSchema,
        page: refreshObj?.page,
      };

      await this.$store.dispatch(ActionType.FETCH_VAULT_ITEMS, fetchObj);
    },
    async deleteSelectedVaultItem() {
      await this.$store.dispatch(ActionType.DELETE_VAULT_ITEM, this.selectedVaultItem);
      await this.fetchSchemas();
    },
    async addItem() {
      await this.selectVaultItem(undefined);
      this._showEditView(true);
    },
    async executeAction(action: Action) {
      this.isExecutingAction = true;

      const vaultifier = getInstance();
      const state = this.$store.state as IStore;
      let response: NetworkResponse | undefined;

      try {
        response = await executeAction(action, vaultifier, undefined);
      } catch (e) {
        console.error(e);
      }

      if (response) {
        const vaultItemId = response.data.id;
        const vaultItem = await vaultifier.getItem({ id: vaultItemId });

        if (!vaultItem.schemaDri) {
          console.error('Vault item does not have schema DRI!');
        } else {
          await this.fetchSchemas();
          const schema = state.schemaDRI.all.find(x => x.dri === vaultItem.schemaDri);

          if (!schema) {
            console.error('Could not find schema DRI in internal list!');
          } else {
            await this.selectSchema(schema);
            await this.selectVaultItem(vaultItem);
          }
        }
      }

      this.isExecutingAction = false;
    },
    async saveVaultItem(postItem: VaultPostItem, onComplete?: () => void) {
      this.saveMessage = undefined;
      this.isSaving = true;

      try {
        await this.$store.dispatch(ActionType.UPDATE_VAULT_ITEM, postItem);
        this._showEditView(false);
      } catch {
        this.saveMessage = 'Could not save item';
      }

      await this.fetchVaultItems();
      this.isSaving = false;

      if (onComplete)
        // indicate saving is complete
        onComplete();
    },
    _showEditView(show: boolean) {
      this.showEditView = show;
      this.saveMessage = undefined;
      this.editViewSchema = this.selectedSchema;

      this.$emit('showEditView', this.showEditView);
    },
  },
  computed: {
    schemaDRIs(): VaultSchema[] {
      return this.$store.state.schemaDRI.all;
    },
    isSchemaListLoading(): boolean {
      return (this.$store.state as IStore).schemaDRI.state === FetchState.FETCHING;
    },
    vaultItems(): VaultItem[] | undefined {
      return this.$store.state.vaultItem.all;
    },
    isVaultItemListLoading(): boolean {
      return (this.$store.state as IStore).vaultItem.allState === FetchState.FETCHING;
    },
    selectedVaultItem(): VaultItem | undefined {
      return (this.$store.state as IStore).vaultItem.current;
    },
    hasSelectedVaultItem(): boolean {
      return !!this.selectedVaultItem;
    },
    isDeleteButtonDisabled(): boolean {
      return !this.hasSelectedVaultItem;
    },
    hasSelectedSchema(): boolean {
      return !!this.selectedSchema;
    },
    editViewSchemaDri(): string | undefined {
      return this.editViewSchema?.dri;
    },
    currentVaultPage(): number | undefined {
      return (this.$store.state as IStore).vaultItem.paging?.current;
    },
    totalVaultItems(): number | undefined {
      return (this.$store.state as IStore).vaultItem.paging?.totalItems;
    },
    vaultPageItems(): number | undefined {
      return (this.$store.state as IStore).vaultItem.paging?.pageItems;
    },
    actions(): Action[] {
      return getActionsFromConfig('settings', 'additionalListActions');
    }
  }
})
