


















































import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isDateTimeControl,
} from '@jsonforms/core';
import { defineComponent, ref } from '@vue/composition-api';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue2';

interface Data {
  state: boolean,
  dateValue?: string,
  timeValue?: string,
}

const DateTimeControlRenderer = defineComponent({
  name: 'date-control-renderer',
  props: {
    ...rendererProps(),
  },
  data: (): Data => ({
    state: false,
    dateValue: undefined,
    timeValue: undefined,
  }),
  setup(props: RendererProps<ControlElement>) {
    const input = ref(null);

    return {
      ...useJsonFormsControl(props),
      input,
    }
  },
  computed: {
    textValue(): string {
      return `${this.dateValue ?? ''}${this.timeValue ? ` ${this.timeValue}` : ''}`;
    }
  },
  mounted() {
    this.validate();
  },
  watch: {
    control: {
      deep: true,
      immediate: true,
      handler: function (val) {
        console.log('now');
        console.log(!!val?.data)
        if (val?.data) {
          // split ISO date
          const parts = val.data.split(/[TZ.]/g);
          this.dateValue = parts[0];
          this.timeValue = parts[1];
        }
        else
          this.dateValue = this.timeValue = undefined;
      },
    },
    textValue() {
      let date: string | undefined = undefined;

      if (this.dateValue && this.timeValue)
        date = `${this.dateValue}T${this.timeValue}Z`;

      this.handleChange(
        this.control.path,
        date,
      );
      this.validate();
    }
  },
  methods: {
    onTimeUpdate(value?: string) {
      this.timeValue = value;
    },
    onDateUpdate(value?: string) {
      this.dateValue = value;
    },
    onTextUpdate(value?: string) {
      if (!value)
        return this.dateValue = this.timeValue = undefined;

      const parts = value.split(' ').filter(x => x !== '');
      this.dateValue = parts[0];
      this.timeValue = parts[1];
    },

    async validate() {
      // we must await the change in the vue reactivity system
      // to notice the new value
      await this.$nextTick();
      // @ts-expect-error
      this.state = this.input?.checkValidity() || false;

      // TODO: also check if it is a valid date value
    },
  }
});
export default DateTimeControlRenderer;
export const entry: JsonFormsRendererRegistryEntry = {
  renderer: DateTimeControlRenderer,
  tester: rankWith(20, isDateTimeControl)
};
