























































import { RoutePath } from '@/router';
import { getInstance } from '@/services';
import { IStore } from '@/store';
import { ActionType } from '@/store/action-type';
import { VaultEncryptionSupport } from 'vaultifier';
import { Action, executeAction, getActionsFromConfig } from '../utils/actions';
import Vue, { PropType } from 'vue'

import { ConfigService, PACKAGE } from '../services/config-service';

interface Data {
  workingAction?: Action,
}

export default Vue.extend({
  props: {
    encryptionSupport: Object as PropType<VaultEncryptionSupport>,
    settingsActions: Array as PropType<string[]>,
    title: String as PropType<string>,
    description: String as PropType<string | undefined>,
    url: String as PropType<string | undefined>,
  },
  data: (): Data => ({
    workingAction: undefined,
  }),
  methods: {
    async executeAction(action: Action) {
      this.workingAction = action;
      await executeAction(action, getInstance(), this);
      this.workingAction = undefined;
    },
    goHome() {
      this.$router.push(RoutePath.MAIN_VIEW);
    },
    toggleUiFluid() {
      this.$store.dispatch(ActionType.TOGGLE_UI_IS_FLUID);
    }
  },
  computed: {
    version() {
      return `${PACKAGE.version}-${PACKAGE.subVersion}`;
    },
    encryptionIcon(): string | undefined {
      if (!this.encryptionSupport)
        return '';

      const { supportsEncryption, supportsDecryption } = this.encryptionSupport;

      if (supportsEncryption && supportsDecryption)
        return 'lock-fill'
      else if (!supportsEncryption && !supportsDecryption)
        return undefined;
      else
        return 'lock';
    },
    actions(): Action[] {
      return getActionsFromConfig('settings', 'actions');
    },
    gearAnimation() {
      return this.workingAction ? 'spin' : undefined;
    },
    workingActionTitle() {
      return this.workingAction?.title;
    },
    state(): IStore {
      return this.$store.state as IStore;
    },
    isUiFluid(): boolean {
      return this.state.ui.isFluid;
    }
  }
})
